const events = [
  {
    path: '/event/memberbonus',
    name: '消費送點數',
    component: () => import('../views/Events/Memberbonus.vue'),
    meta: {
      title: 'papawash 消費送點數'
    }
  },
  {
    path: '/event/moisture',
    name: 'Papawash加強保濕型 限定限量上市',
    component: () => import('../views/Events/Moisture.vue'),
    meta: {
      title: 'Papawash加強保濕型 限定限量上市'
    }
  },
  {
    path: '/event/mommy',
    name: '寵愛媽咪',
    component: () => import('../views/Events/Mommy.vue'),
    meta: {
      title: '寵愛媽咪'
    }
  },
  {
    path: '/event/camu',
    name: 'C卡姆',
    component: () => import('../views/Events/Camu.vue'),
    meta: {
      title: 'C卡姆'
    }
  },
  {
    path: '/event/anniversary38',
    name: '歡慶38歲 重磅福利',
    component: () => import('../views/Events/Anniversary38.vue'),
    meta: {
      title: '歡慶38歲 重磅福利'
    }
  },
  {
    path: '/event/trials',
    name: '領取試用包介紹頁面',
    component: () => import('../views/Events/Trials.vue'),
    meta: {
      title: '領取試用包介紹頁面'
    }
  },
  {
    path: '/event/beautyshow',
    name: 'Papawash 聯邦美容展',
    component: () => import('../views/EventList/BeautyShow.vue'),
    meta: {
      title: 'Papawash 聯邦美容展'
    }
  },
  {
    path: '/event/unionrentalcar',
    name: '聯邦租車 x Papawash',
    component: () => import('../views/EventList/UnionRentalCar.vue'),
    meta: {
      title: '聯邦租車 x Papawash'
    }
  }
  // {
  //   path: '/cardbirthdaygift',
  //   name: '聯邦卡友生日禮活動',
  //   component: () => import('../views/EventList/CardBirthdayGift.vue'),
  //   meta: {
  //     title: 'Papawash 聯邦卡友生日禮活動'
  //   }
  // }
  // {
  //   path: '/event/applytrials',
  //   name: '領取試用包',
  //   component: () => import('../views/Events/TrialsApply.vue'),
  //   meta: {
  //     title: '領取試用包'
  //   }
  // }
]

export default events
